import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri"

export default function ThreeSteps() {
  return (
    <Container>
      <ThreeCardsShadow
        text="Book here and reserve your spot for the tour."
        icon={<RiNumber1 />}
        text2="Pay the remaining 75% after your arrival in Costa Rica."
        icon2={<RiNumber2 />}
        text3="Book a flight and enjoy a journey of a lifetime!"
        icon3={<RiNumber3 />}
      />
    </Container>
  )
}
