import React from "react"
import Testimonial from "./testimonials"

export default function BadBaldi() {
  return (
    <Testimonial
      quote="Do NOT miss out on this!"
      name="Sarah"
      topic="Raving about the Hot Springs in Bad Baldi"
    />
  )
}
