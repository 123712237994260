import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"

import Book from "../components/booking/book"
import DetailsPrice from "../components/tourDetails/daysPrice"
import IncludedDance from "../components/included/dance"
import IncludedGuide from "../components/included/guide"
import ThreeSteps from "../components/booking/threeSteps"
import Cta from "../components/cta"

import TestimonialBadBaldi from "../components/testimonials/badBaldi"

import Seo from "../components/Seo/seo"
import ProductJson from "../components/Seo/productJson"

export default function Booking({ data }) {
  const info = data.site.siteMetadata
  return (
    <Layout>
      <Seo
        title="Book your adventure in Costa Rica here"
        description="Discover authentic Costa Rica, dance at the best parties, climb volcanos, discover jungle secrets and swim in the Caribbean and the Pacific. Book your adventure in Costa Rica here."
      />
      <ProductJson />
      <Container>
        <h1 className="display-1 text-center my-5 lstitle text-uppercase">
          Book Your Adventure here
        </h1>
        <h2 className="fw-lighter display-5 text-center mb-5">
          Join the Costa Rica Tour in three simple steps:
        </h2>
        <ThreeSteps link={info.bookingLink} />
        <Book
          flyer={data.file}
          link={info.bookingLink}
          price={info.price}
          priceMember={info.priceMember}
          prePaiment={info.prePaiment}
        />

        <DetailsPrice />
        <IncludedDance />
        <IncludedGuide />
        <p className="text-center display-5">...and much, much more!</p>
      </Container>
      <TestimonialBadBaldi />
      <div className="mb-5 mt-5" />
      <Cta
        title="Want to know more?"
        subtitle="Discover what's included in LatinSalsa's Costa Rican Tour"
        link="/about"
        btn="Discover the tour"
        bgcolor="#a70c36"
        txtcolor="text-white"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        bookingLink
        nextTourDate
        price
        priceMember
        prePaiment
        duration
      }
    }
    file(name: { eq: "flyer" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
