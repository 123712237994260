import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { ImWoman } from "react-icons/im"
import { TbMessageLanguage } from "react-icons/tb"
import { MdOutlineBedtime } from "react-icons/md"

export default function IncludedGuide() {
  return (
    <Container>
      <ThreeCardsShadow
        title="German, English, Spanish spoken"
        icon={<TbMessageLanguage />}
        title2="Native Costa Rican Guide"
        icon2={<ImWoman />}
        title3="Single Room Optional (extra charge)"
        icon3={<MdOutlineBedtime />}
      />
    </Container>
  )
}
