import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiAztecCalendarSun } from "react-icons/gi"
import { BsCalendar2Date, BsCurrencyEuro } from "react-icons/bs"
import { useStaticQuery, graphql } from "gatsby"

export default function DetailsPrice() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          bookingLink
          nextTourDate
          price
          priceMember
          prePaiment
          duration
        }
      }
    }
  `)
  const info = data.site.siteMetadata
  return (
    <Container>
      <ThreeCardsShadow
        title="Date"
        text={info.nextTourDate}
        icon={<BsCalendar2Date />}
        title2="Price"
        text2={`${info.price}€ (${info.priceMember}€ for LatinSalsa Members)`}
        icon2={<BsCurrencyEuro />}
        title3="Duration"
        text3={info.duration}
        icon3={<GiAztecCalendarSun />}
      />
    </Container>
  )
}
