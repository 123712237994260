import React from "react"
import Container from "react-bootstrap/Container"
import BookCta from "./bookCta"

export default function Book({ flyer, link, price, priceMember, prePaiment }) {
  return (
    <Container>
      <BookCta
        title={`BOOK THE FULL TOUR FOR ONLY ${price}€`}
        text={`Reserve your spot now for ${prePaiment}€`}
        subtext={`Only ${priceMember}€ for LatinSalsa Members`}
        subtext2="Does not include flight tickets."
        link={link}
        btn="Book Now"
        img={flyer}
        imgAlt="Flyer for LatinSalsa Tours"
      />
    </Container>
  )
}
