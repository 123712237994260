import React from "react"
import JsonLd from "./jsonld"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

export default function ProductJson() {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const { image, price, brand, priceValid, siteUrl, lsDe, legalName } =
    site.siteMetadata
  return (
    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Product",

        description: `Tour in Costa Rica: Dance in paradise and discover one of the most biodiverse countries on earth!`,
        sku: "lstour23",
        brand: {
          "@type": "Brand",
          name: `${brand}`,
        },

        name: "Costa Rica Tour",
        image: `${siteUrl}${image}`,
        offers: {
          "@type": "Offer",
          availability: "https://schema.org/InStock",
          price: `${price}.00`,
          priceValidUntil: `${priceValid}`,
          priceCurrency: "EUR",
          url: `${siteUrl}${pathname}`,
          eligibleRegion: [
            "DE",
            "FR",
            "ES",
            "AT",
            "NL",
            "LU",
            "CR",
            "PL",
            "IT",
            "BE",
          ],
          offeredBy: {
            "@type": "Organization",
            name: `${legalName}`,
            url: `${lsDe}`,
          },
        },
      }}
    </JsonLd>
  )
}

const query = graphql`
  query ProductJson {
    site {
      siteMetadata {
        image
        price
        brand
        legalName
        siteUrl
        lsDe
        priceValid
        siteUrl
      }
    }
  }
`
