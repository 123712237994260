import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { FaHandsHelping, FaRegSmileWink } from "react-icons/fa"

import { GiWingfoot } from "react-icons/gi"

export default function IncludedDance() {
  return (
    <Container>
      <ThreeCardsShadow
        title="14h of Dance Classes"
        icon={<GiWingfoot />}
        title2="Professional Dance Teacher at Your Side"
        icon2={<FaHandsHelping />}
        title3="At least 1 Party per Region"
        icon3={<FaRegSmileWink />}
      />
    </Container>
  )
}
